import * as React from "react"
import TransitionLink from 'gatsby-plugin-transition-link';
import entryTransition from '../components/header';
import exitTransition from '../components/header';

const NotFoundPage = () => {
  return (
    <section className="error404 text-center">
      <div className="anim pt pb-100">
        <h1>Page not found</h1>
        <TransitionLink
          to="/"
          exit={exitTransition}
          entry={entryTransition}
        >
          Go home
        </TransitionLink>
      </div>
    </section>
  )
}

export default NotFoundPage


export function Head() {
  return (
    <>
      <title>404 - MADE - Design. Brand. Technology.</title>
      <meta name="robots" content="noindex, nofollow"/>
    </>
  )
}
